import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckIcon } from "@heroicons/react/outline";

const Pricing = () => {
  const intl = useIntl();

  const tiers = [
    {
      name: intl.formatMessage({ id: "PRICING_1_NAME" }),
      priceAnnually: 0,
      description: intl.formatMessage({ id: "PRICING_1_DESCRIPTION" }),
      features: [
        intl.formatMessage({ id: "PRICING_1_FEATURE_1" }),
        intl.formatMessage({ id: "PRICING_1_FEATURE_2" }),
        intl.formatMessage({ id: "PRICING_1_FEATURE_3" }),
        intl.formatMessage({ id: "PRICING_1_FEATURE_4" }),
        intl.formatMessage({ id: "PRICING_1_FEATURE_5" }),
      ],
    },
    {
      name: intl.formatMessage({ id: "PRICING_2_NAME" }),
      priceAnnually: 10,
      description: intl.formatMessage({ id: "PRICING_2_DESCRIPTION" }),
      features: [
        intl.formatMessage({ id: "PRICING_2_FEATURE_1" }),
        intl.formatMessage({ id: "PRICING_2_FEATURE_2" }),
        intl.formatMessage({ id: "PRICING_2_FEATURE_3" }),
        intl.formatMessage({ id: "PRICING_2_FEATURE_4" }),
        intl.formatMessage({ id: "PRICING_2_FEATURE_5" }),
      ],
    },
  ];

  return (
    <div className="bg-pink-700">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              <FormattedMessage id="PRICING_TITLE_TOP" />
            </h2>
            <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              <FormattedMessage id="PRICING_TITLE_MAIN" />
            </p>
            <p className="text-xl text-gray-300">
              <FormattedMessage id="PRICING_TITLE_DESCRIPTION" />
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-pink-700" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-pink-100 text-pink-700"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      CHF {tier.priceAnnually}.-
                      <span className="ml-1 text-2xl font-medium text-gray-500">
                        / <FormattedMessage id="PRICING_PERIOD" />
                      </span>
                    </div>
                    <p className="mt-5 text-lg text-gray-500">
                      {tier.description}
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul role="list" className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-pink-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
