import React from "react";
import {
  PaperClipIcon,
  DeviceMobileIcon,
  LightningBoltIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { FormattedMessage, useIntl } from "react-intl";

const Features = () => {
  const intl = useIntl();

  const features = [
    {
      name: intl.formatMessage({ id: "FEATURE_1_NAME" }),
      description: intl.formatMessage({ id: "FEATURE_1_DESCRIPTION" }),
      icon: LightningBoltIcon,
    },
    {
      name: intl.formatMessage({ id: "FEATURE_2_NAME" }),
      description: intl.formatMessage({ id: "FEATURE_2_DESCRIPTION" }),
      icon: DeviceMobileIcon,
    },
    {
      name: intl.formatMessage({ id: "FEATURE_3_NAME" }),
      description: intl.formatMessage({ id: "FEATURE_3_DESCRIPTION" }),
      icon: SearchIcon,
    },
    {
      name: intl.formatMessage({ id: "FEATURE_4_NAME" }),
      description: intl.formatMessage({ id: "FEATURE_4_DESCRIPTION" }),
      icon: PaperClipIcon,
    },
  ];

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-pink-600 font-semibold tracking-wide uppercase">
            <FormattedMessage id="FEATURES_TITLE_TOP" />
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <FormattedMessage id="FEATURES_TITLE_MAIN" />
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            <FormattedMessage id="FEATURES_DESCRIPTION" />
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-pink-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <h3 className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </h3>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
