import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const PosterDownload = () => {
  const intl = useIntl();

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            <FormattedMessage id="POSTER_DOWNLOAD_TITLE" />
          </h2>
          <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
            <FormattedMessage id="POSTER_DOWNLOAD_DESCRIPTION" />
          </p>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200"
                href={intl.formatMessage({ id: "POSTER_DOWNLOAD_A3_URL" })}
                target="_blank"
              >
                <FormattedMessage id="POSTER_DOWNLOAD_A3_TEXT" />
              </a>
            </div>
            <div className="ml-3 inline-flex">
              <a
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-pink-100 hover:bg-pink-200"
                href={intl.formatMessage({ id: "POSTER_DOWNLOAD_A1_URL" })}
                target="_blank"
              >
                <FormattedMessage id="POSTER_DOWNLOAD_A1_TEXT" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosterDownload;
